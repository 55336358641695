import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedGuard, PublicGuard } from 'ngx-auth';
import { _ } from '../../../modules/shared/helpers/translation-marker';
import { NoCompanyPageComponent } from '../pages/no-company-page/no-company-page.component';
import { PrivateRootComponent } from '../pages/private-root/private-root.component';
import { LoadSegmentGroupsGuard } from './guards/load-segment-groups.guard';

import { LoadSegmentModelsGuard } from './guards/load-segment-models.guard';
import { LoadSegmentPropertiesGuard } from './guards/load-segment-properties.guard';
import { LoadUserCompaniesGuard } from './guards/load-user-companies.guard';
import { LoadUserGuard } from './guards/load-user.guard';
import { NotFoundComponent } from '../pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../../modules/auth/auth-panel.module').then(m => m.AuthPanelModule),
    canActivate: [PublicGuard],
  },
  {
    path: 'no-company',
    component: NoCompanyPageComponent,
    canActivate: [ProtectedGuard, LoadUserGuard],
  },
  {
    path: '',
    canActivate: [ProtectedGuard],
    children: [
      {
        path: '',
        component: PrivateRootComponent,
        canActivate: [
          ProtectedGuard,
          LoadUserCompaniesGuard,
          LoadSegmentModelsGuard,
          LoadSegmentPropertiesGuard,
          LoadSegmentGroupsGuard,
        ],
        data: { crumb: { name: _('Home') } },
        children: [
          // {
          //   path: 'batches',
          //   loadChildren: '../modules/batch/batch.module#BatchModule'
          // },
          {
            path: 'segments',
            loadChildren:
              () => import('../../../modules/segments/segments.module').then(m => m.SegmentsModule),
          },
          {
            path: 'promotions',
            loadChildren:
              () => import('../../../modules/promotions/promotions.module').then(m => m.PromotionsModule),
          },
          {
            path: 'messaging',
            loadChildren:
              () => import('../../../modules/messaging/messaging.module').then(m => m.MessagingModule),
          },
          {
            path: 'profile',
            loadChildren:
              () => import('../../../modules/profile/profile.module').then(m => m.ProfileModule),
          },
          {
            path: 'product-categories',
            loadChildren:
              () => import('../../../modules/product-categories/product-categories.module').then(m => m.ProductCategoriesModule),
          },
          {
            path: 'products',
            loadChildren:
              () => import('../../../modules/products/products.module').then(m => m.ProductsModule),
          },
          {
            path: 'dictionaries',
            loadChildren:
              () => import('../../../modules/dictionaries/dictionaries.module').then(m => m.DictionariesModule),
          },
          {
            path: 'outlets',
            loadChildren:
              () => import('../../../modules/outlets/outlets.module').then(m => m.OutletsModule),
          },
          {
            path: 'pages',
            loadChildren: () => import('../../../modules/pages/pages.module').then(m => m.PagesModule),
          },
          {
            path: 'banners',
            loadChildren:
              () => import('../../../modules/banners/banners.module').then(m => m.BannersModule),
          },
          {
            path: 'templates',
            loadChildren:
              () => import('../../../modules/promotion-triggers/promotion-triggers.module').then(m => m.PromotionTriggersModule),
          },
          {
            path: 'employees',
            loadChildren:
              () => import('../../../modules/employees-control/employees-control.module').then(m => m.EmployeesControlModule),
          },
          {
            path: '',
            redirectTo: '/promotions/active',
            pathMatch: 'full',
          },
          {
            path: '**',
            data: { crumb: { name: _('Page not found') } },
            component: NotFoundComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
