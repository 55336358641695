import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { SegmentPropertiesLoad } from '../../../../modules/segments/store/actions/segment-properties.action';
import { getCurrentCompany } from '../../../../modules/shared/store/selectors/user.selector';
import { filter, tap, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadSegmentPropertiesGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(getCurrentCompany).pipe(
      filter(data => !!data),
      tap(() => {
        this.store.dispatch(new SegmentPropertiesLoad());
      }),
      mapTo(true)
    );
  }
}
