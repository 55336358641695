import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppState } from '../../../../store/reducers/index';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SegmentModel } from '../../models/segment-model';
import { getSegmentModelsList, isLoadingSegmentModels } from '../../store/selectors/segment-models.selector';
import { SegmentModelMenuItemNames } from '../../models/segment';

@Component({
  selector: 'app-segment-models',
  templateUrl: './segment-models.component.html',
  styleUrls: ['./segment-models.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentModelsComponent implements OnInit {
  models$: Observable<SegmentModel[]>;
  isLoading$: Observable<boolean>;
  constructor(
    private store: Store<AppState>
  ) {
    this.models$ = this.store.pipe(
      select(getSegmentModelsList)
    );
    this.isLoading$ = this.store.pipe(
      select(isLoadingSegmentModels)
    );
  }

  ngOnInit() {
  }

  getModelName(name: string, model: any): string {
    return SegmentModelMenuItemNames[name];
  }
}
