<app-sidebar>
  <ul class="nav nav-primary" *ngIf="roles$ | async as roles">
    <li class="nav-item" *ngIf="roles.includes('PROMOTIONS')">
      <a class="nav-link" (click)="toggle('PROMOTIONS')">
        <i class="icon icon-promotion"></i> {{ "Promotions" | translate }}
        <i class="expand-icon material-icons">{{
          !expand["PROMOTIONS"] ? "expand_more" : "expand_less"
        }}</i></a
      >

      <app-promotion-states-menu
        *ngIf="expand['PROMOTIONS']"
      ></app-promotion-states-menu>
    </li>
    <li
      class="nav-item"
      *ngIf="roles.includes('PROMOTIONS')"
    >
      <a class="nav-link" (click)="toggle('MESSAGING')">
        <i class="material-icons">descriptions</i> {{ "Messaging" | translate }}
        <i class="expand-icon material-icons">{{
          !expand["MESSAGING"] ? "expand_more" : "expand_less"
          }}</i></a
      >
      <ul class="nav" *ngIf="expand['MESSAGING']">
        <li class="nav-item" *ngIf="roles.includes('PROMOTIONS')">
          <a class="nav-link" routerLink="/messaging/sms" routerLinkActive="active">
            {{ "SMS" | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="roles.includes('PROMOTIONS')">
          <a class="nav-link" routerLink="/messaging/mail" routerLinkActive="active">
            {{ "Email batches" | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="roles.includes('PROMOTIONS')">
          <a class="nav-link" routerLink="/messaging/trigger" routerLinkActive="active">
            {{ "Triggers" | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="roles.includes('PROMOTIONS')">
          <a class="nav-link" routerLink="/messaging/push" routerLinkActive="active">
            {{ "Push" | translate }}
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="roles.includes('SEGMENTS')">
      <a class="nav-link" (click)="toggle('SEGMENTS')">
        <i class="icon icon-segment"></i> {{ "Segments" | translate }}
        <i class="expand-icon material-icons">{{
          !expand["SEGMENTS"] ? "expand_more" : "expand_less"
        }}</i></a
      >
      <app-segment-models *ngIf="expand['SEGMENTS']"></app-segment-models>
    </li>
    <li
      class="nav-item"
      *ngIf="roles.includes('PRODUCT_CATEGORIES') || roles.includes('PRODUCTS')"
    >
      <a class="nav-link" (click)="toggle('CATALOG')">
        <i class="icon icon-catalog"></i> {{ "Catalog" | translate }}
        <i class="expand-icon material-icons">{{
          !expand["CATALOG"] ? "expand_more" : "expand_less"
        }}</i></a
      >
      <ul class="nav" *ngIf="expand['CATALOG']">
        <li class="nav-item" *ngIf="roles.includes('PRODUCT_CATEGORIES')">
          <a
            class="nav-link"
            routerLink="/product-categories"
            routerLinkActive="active"
            >{{ "Categories" | translate }}</a
          >
        </li>
        <li class="nav-item" *ngIf="roles.includes('PRODUCTS')">
          <a
            class="nav-link"
            routerLink="/products"
            routerLinkActive="active"
            >{{ "Products" | translate }}</a
          >
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="roles.includes('OUTLETS')">
      <a class="nav-link" routerLink="/outlets">
        <i class="material-icons">store</i> {{ "Outlets" | translate }}</a
      >
    </li>
    <li class="nav-item" *ngIf="roles.includes('DICTIONARIES')">
      <a class="nav-link" routerLink="/dictionaries">
        <i class="material-icons">list_alt</i> {{ "Dictionaries" | translate }}
      </a>
    </li>
    <li class="nav-item" *ngIf="roles.includes('BANNERS')">
      <a class="nav-link" routerLink="/banners">
        <i class="material-icons">view_module</i> {{ "Banners" | translate }}
      </a>
    </li>
    <li class="nav-item" *ngIf="roles.includes('EMPLOYEES')">
      <a class="nav-link" routerLink="/employees">
        <i class="material-icons">view_module</i> {{ "Employees" | translate }}
      </a>
    </li>
    <li
      class="nav-item"
      *ngIf="roles.includes('CONTENT') || roles.includes('PROMOTIONS')"
    >
      <a class="nav-link" (click)="toggle('CONTENT')">
        <i class="material-icons">descriptions</i> {{ "Content" | translate }}
        <i class="expand-icon material-icons">{{
          !expand["CONTENT"] ? "expand_more" : "expand_less"
        }}</i></a
      >
      <ul class="nav" *ngIf="expand['CONTENT']">
        <li class="nav-item" *ngIf="roles.includes('PROMOTIONS')">
          <a class="nav-link" routerLink="/templates" routerLinkActive="active">
            {{ "Templates" | translate }}
          </a>
        </li>
        <li class="nav-item" *ngIf="roles.includes('CONTENT')">
          <a class="nav-link" routerLink="/pages" routerLinkActive="active">
            {{ "Pages" | translate }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</app-sidebar>
<div class="workspace">
  <app-page-header></app-page-header>
  <router-outlet></router-outlet>
</div>
