import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  LanguageItem,
  ProfileService
} from '../../modules/profile/services/proifle/profile.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(
    translateService: TranslateService,
    profileService: ProfileService
  ) {
    translateService.setDefaultLang('ru');
    profileService
      .availableLanguages()
      .pipe(take(1))
      .subscribe((languages: LanguageItem[]) => {
        const langs = languages.map(it => it.value);
        translateService.addLangs(['ru', 'en']);
        langs.forEach(lang => translateService.reloadLang(lang));
      });
  }
}
