import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';
import { HandleGuardRequestService } from '../../../../modules/shared/services/handle-guard-request/handle-guard-request.service';
import { LOAD_USER_FAIL, LoadUser } from '../../../../modules/shared/store/actions/user.action';
import { getCurrentUser } from '../../../../modules/shared/store/selectors/user.selector';
import { AppState } from '../../../../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class LoadUserGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private handler: HandleGuardRequestService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new LoadUser());
    return this.handler.handle(
      this.store.select(getCurrentUser).pipe(filter((user) => {
          if (!!user) {
            return true;
          } else {
            this.router.navigate(['/']);
          }
        }),
        mapTo(true)
      ),
      LOAD_USER_FAIL
    );
  }
}
