import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  Company,
  CompanyRole
} from '../../../../modules/shared/models/companies';
import { getCompanyRoles } from '../../../../modules/shared/store/selectors/user.selector';
import { AppState } from '../../../../store/reducers';

const SIDEBAR_EXPANDED = 'SIDEBAR_EXPANDED';
@Component({
  selector: 'app-private-root',
  templateUrl: './private-root.component.html',
  styleUrls: ['./private-root.component.scss']
})
export class PrivateRootComponent implements OnInit {
  roles$: Observable<CompanyRole[]>;
  expand: { [key: string]: boolean } =
    JSON.parse(localStorage.getItem(SIDEBAR_EXPANDED)) || {};

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.roles$ = this.store.pipe(select(getCompanyRoles));
  }

  toggle(key) {
    this.expand[key] = !this.expand[key];
    localStorage.setItem(SIDEBAR_EXPANDED, JSON.stringify(this.expand));
  }
}
