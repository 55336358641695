import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';
import { ProfileService } from '../../../../modules/profile/services/proifle/profile.service';
import { Company } from '../../../../modules/shared/models/companies';
import { User } from '../../../../modules/shared/models/user';
import { HandleGuardRequestService } from '../../../../modules/shared/services/handle-guard-request/handle-guard-request.service';
import { LOAD_COMPANIES_FAIL, LOAD_USER_FAIL, LoadCompanies, LoadUser } from '../../../../modules/shared/store/actions/user.action';
import { getCurrentCompany, getCurrentUser } from '../../../../modules/shared/store/selectors/user.selector';
import { AppState } from '../../../../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class LoadUserCompaniesGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private handler: HandleGuardRequestService,
    private profileService: ProfileService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new LoadUser());
    this.store.dispatch(new LoadCompanies());
    return this.handler.handle(
      combineLatest(
        this.store.select(getCurrentUser),
        this.store.select(getCurrentCompany)
      ).pipe(
        filter(([user, companies]: [User, Company]) => {
          if (!!user) {
            if (companies) {
              return true;
            } else if (companies === null) {
              return false;
            } else {
              this.router.navigate(['/no-company']);
            }
          }
        }),
        mapTo(true)
      ),
      LOAD_USER_FAIL,
      LOAD_COMPANIES_FAIL
    );
  }
}
