import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import {
  SEGMENT_MODELS_LOAD_FAIL,
  SegmentModelsLoad
} from '../../../../modules/segments/store/actions/segment-models.action';
import { getSegmentModels } from '../../../../modules/segments/store/reducers/segment-models.reducer';
import { filter, mapTo } from 'rxjs/operators';
import { HandleGuardRequestService } from '../../../../modules/shared/services/handle-guard-request/handle-guard-request.service';
import {
  SEGMENT_LOAD_FAIL,
  SEGMENTS_LOAD_FAIL
} from '../../../../modules/segments/store/actions/segments.action';

@Injectable({
  providedIn: 'root'
})
export class LoadSegmentModelsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private handler: HandleGuardRequestService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new SegmentModelsLoad());
    return this.handler.handle(
      this.store.select(getSegmentModels).pipe(
        filter(data => !!data.segmentModels.length),
        mapTo(true)
      ),
      SEGMENT_MODELS_LOAD_FAIL
    );
  }
}
