import { Component } from '@angular/core';

@Component({
  selector: 'app-no-company-page',
  templateUrl: './no-company-page.component.html',
  styleUrls: ['./no-company-page.component.scss']
})
export class NoCompanyPageComponent {

  constructor() { }
}
