import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import {
  SEGMENTS_GROUPS_LOAD_FAIL,
  SEGMENTS_GROUPS_LOAD_SUCCESS,
  SegmentsGroupsLoad
} from '../../../../modules/segments/store/actions/segment-groups.action';
import { mapTo, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { HandleGuardRequestService } from 'src/app/modules/shared/services/handle-guard-request/handle-guard-request.service';

@Injectable({
  providedIn: 'root'
})
export class LoadSegmentGroupsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private handler: HandleGuardRequestService,
    private actions$: Actions
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new SegmentsGroupsLoad());
    return this.actions$.pipe(
      ofType(SEGMENTS_GROUPS_LOAD_SUCCESS, SEGMENTS_GROUPS_LOAD_FAIL),
      take(1),
      mapTo(true)
    );
  }
}
