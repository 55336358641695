<ul class="nav">
  <li class="nav-item">
    <a
      routerLink="/promotions/draft"
      routerLinkActive="active"
      class="nav-link"
      >{{ 'Drafts' | translate }}</a
    >
  </li>
  <li class="nav-item">
    <a
      routerLink="/promotions/active"
      routerLinkActive="active"
      class="nav-link"
      >{{ 'Active' | translate }}</a
    >
  </li>
  <li class="nav-item">
    <a
      routerLink="/promotions/completed"
      routerLinkActive="active"
      class="nav-link"
      >{{ 'Completed' | translate }}</a
    >
  </li>
  <li class="nav-item">
    <a
      routerLink="/promotions/archived"
      routerLinkActive="active"
      class="nav-link"
      >{{ 'Archived' | translate }}</a
    >
  </li>
</ul>
